// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-tsx": () => import("./../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prepare-group-2-js": () => import("./../src/pages/prepare-group-2.js" /* webpackChunkName: "component---src-pages-prepare-group-2-js" */),
  "component---src-pages-prepare-group-3-js": () => import("./../src/pages/prepare-group-3.js" /* webpackChunkName: "component---src-pages-prepare-group-3-js" */),
  "component---src-pages-prepare-group-4-js": () => import("./../src/pages/prepare-group-4.js" /* webpackChunkName: "component---src-pages-prepare-group-4-js" */),
  "component---src-pages-quizzes-js": () => import("./../src/pages/quizzes.js" /* webpackChunkName: "component---src-pages-quizzes-js" */),
  "component---src-templates-archive-js": () => import("./../src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-companies-js": () => import("./../src/templates/companies.js" /* webpackChunkName: "component---src-templates-companies-js" */),
  "component---src-templates-operators-js": () => import("./../src/templates/operators.js" /* webpackChunkName: "component---src-templates-operators-js" */),
  "component---src-templates-telis-js": () => import("./../src/templates/telis.js" /* webpackChunkName: "component---src-templates-telis-js" */)
}

